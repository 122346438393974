.overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  background-color: rgba(13, 13, 13, 0.5);
}

.content {
  width: 80%;
  max-height: 80%;
  max-width: 1224px;
  background-color: rgba(13, 13, 13, 1);
  border: 1px solid rgba(255, 255, 255, 1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: 10% auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.closeBtn {
  font-size: 2em;
  border: none;
  background-color: transparent;
  padding: 0px;
}

@media only screen and (min-width: 600px) {
  .content {
    padding: 50px;
    margin-bottom: 0px;
  }

  .closeBtn {
    transition: transform 0.25s;
    cursor: pointer;
  }

  .closeBtn:hover {
    transform: scale(1.5);
  }
}