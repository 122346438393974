.container {
  width: 150px;
  height: 200px;
  border: 1px solid rgba(255, 255, 255, 1);
  background-color: transparent;
  padding: 0px;
}

.companyLogo {
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.companyLogoValidus {
  background-image: url('./assets/companies/validus.png');
}

.companyLogoAmd {
  background-image: url('./assets/companies/adam-matthew.jpg');
  background-color: rgb(127,13,64);
}

.companyLogoCs {
  background-image: url('./assets/companies/creditsafe.jpg');
  background-color: rgb(239,42,36);
}

.companyLogoMaxContact {
  background-image: url('./assets/companies/max-contact.png');
}

.companyLogoCortex {
  background-image: url('./assets/companies/cortex.png');
}

.overlay {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to top,
    rgba(13, 13, 13, 0.9),
    rgba(13, 13, 13, 0.8),
    rgba(13, 13, 13, 0.8),
    rgba(13, 13, 13, 0.5));
}

.content {
  width: 100%;
  height: 100%;
  position: relative;
}

.title {
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.container,
.companyLogo,
.overlay {
  border-radius: 15px;
}

.modalLogo {
  width: 20%;
  margin: 0px auto 40px auto;
}

.borderedLogo {
  border-radius: 50%;
}

@media only screen and (min-width: 600px) {
  .container {
    width: 200px;
    height: 300px;
    transition: border 0.2s;
    cursor: pointer;
  }

  .overlay {
    opacity: 0;
  }

  .container:hover {
    border-width: 10px;
  }

  .companyLogo,
  .overlay {
    transition: opacity 0.2s, background-size 0.2s, border-radius 0.2s;
  }

  .container:hover .companyLogo,
  .container:hover .overlay {
    border-radius: 0px;
  }

  .container:hover .companyLogo {
    background-size: 125%;
    border-radius: 0px;
  }

  .container:hover .overlay {
    opacity: 1;
  }  
}