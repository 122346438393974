.container {
  padding: 30px;
  text-align: justify;
}

.slogan {
  font-family: aleoitalic, serif;
  font-size: 1.2em;
  text-align: center;
  margin: 0px;
}

.sections {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
      "about"
      "skills"
      "experience";
}

.about {
  grid-area: about;
}

.skills {
  grid-area: skills;
}

.experience {
  grid-area: experience;
}

@media only screen and (min-width: 600px) {
  .slogan {
    font-size: 1.5em;
  }
}

@media only screen and (min-width: 1224px) {
  .sections {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "about skills"
      "experience experience";
    max-width: 1224px;
    margin: auto;
  }

  .sections {
    gap: 40px;
  }
}