.navMenuItems {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  text-align: center;
}

.navMenuLink {
  text-decoration: none;
}
