.container {
  padding-bottom: 20px;
}

.cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

@media only screen and (min-width: 600px) {
  .cards {
    gap: 50px;
  }
}