.container,
.footer {
  width: 100%;
  display: flex;
}

.container {
  height: 60px;
  justify-content: center;
  background-color: #EDEDED;
}

.footer {
  height: 100%;
  max-width: 1000px;
  justify-content: space-between;
  align-items: center;
}

.navMenuItems {
  list-style-type: none;
  margin: 0px;
  display: flex;
  align-items: baseline;
}

.navMenuLink {
  text-decoration: none;
}

.navMenuItems > li:not(:last-child) {
  border-right: 1px solid #0D0D0D;
}

.navMenuItems,
.navMenuLinkText {
  padding: 0px 10px;
}

.linkedinBtn {
  font-size: 2rem;
  padding: 0px 20px;
  transition: transform 0.2s;
}

.navMenuLinkText,
.navMenuItems > li:not(:last-child)::after,
.linkedinBtn path {
  color: #0D0D0D;
}

.navMenuLinkText {
  margin: 0px;
  font-size: 1.2rem;
}

@media only screen and (min-width: 600px) {
  .container {   
    height: 90px;
  }

  .navMenuLinkText:hover {
    text-decoration: underline;
  }

  .linkedinBtn:hover {
    transform: scale(1.5);
  }
}