.container {
  padding-bottom: 50px;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

@media only screen and (min-width: 600px) {
  .content {
    gap: 80px;
  }
}