.h1,
.h2,
.p {
  margin: 0px;
  padding: 0px;
  display: inline-block;
  text-align: justify;
}

.h1,
.h2,
.p * {
  display: inline-block;
}

.h1,
.p {
  font-family: Aleo, serif;
}

.h1 {
  font-size: 2rem;
  font-weight: 400;
}

.h2 {
  font-family: Oswald;
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: uppercase;
}

.gutterBottom {
  padding-bottom: 30px;
}

.alignCenter {
  text-align: center;
  width: 100%;
}