.container {
  box-sizing: border-box;
  background-color: #0D0D0D;
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  padding: 0px;
}

.title {
  margin: 0px 0px 0px 30px;
}

@media only screen and (min-width: 600px) {
  .container {   
    height: 120px;
  }

  .title {
    font-size: 2em;
    margin: 0px 0px 0px 60px;
  }
}