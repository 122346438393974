.navMenuItems {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0px 20px;
}

.navMenuItems > li:not(:last-child) {
  border-right: 2px solid #FFFFFF;
}

.navMenuLink {
  padding: 0px 30px;
}

.navMenuLink:hover {
  text-decoration: underline;
}