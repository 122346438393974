.skillBtn {
  padding: 0px;
  margin: auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.skillBtnText {
  font-size: 1.25rem;
}

.skillModalLogo {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

@media only screen and (min-width: 600px) {
  .skillBtn {
    transition: transform 0.25s;
  }

  .skillBtn:hover {
    transform: scale(1.2);
  }

  .skillModalLogo {
    margin-bottom: 40px;
  }
}