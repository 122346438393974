.burgerBarBtn {
  border: none;
  background-color: transparent;
  margin-right: 15px;
}

.burgerBarIconOpen {
  font-size: 1.75em;
}

.burgerBarIconClose {
  font-size: 2em;
}

.navMenu {
  background-color: #0D0D0D;
  position: absolute;
  top: 60px;
  width: 100%;
  z-index: 20;
}

.navMenuItem {
  margin-bottom: 10px;
}

.navMenuItem:first-child {
  margin-top: 20px;
}

.overflow {
  background-color: #0D0D0D;
  position: absolute;
  top: 60px;
  width: 100%;
  min-height: calc(100vh - 60px);
  z-index: 10;
  opacity: 0.5;
}

@media only screen and (min-width: 600px) {
  .navMenu,
  .overflow {   
    top: 120px;
  }
  .overflow {
    min-height: calc(100vh - 120px);
  }
}