.skillBtnLogoOutline {
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 1);
  margin-bottom: 10px;
  height: 89px;
  width: 89px;
}

.skillBtnLogoReact {
  width: 75px;
  height: 75px;
}

.skillBtnLogoOutlineReact {
  padding: 5px;
}

.skillBtnLogoOutlinePython,
.skillBtnLogoOutlineScrum {
  padding: 12.5px;
}

.skillBtnLogoPython,
.skillBtnLogoScrum {
  width: 60px;
  height: 60px;
}

.skillBtnLogoDotNet,
.skillBtnLogoAws {
  width: 85px;
  height: 85px;
  border-radius: 50%;
}

@media only screen and (min-width: 600px) {
  .skillBtnLogoOutline {
    height: 104px;
    width: 104px;
  }

  .skillBtnLogoReact {
    width: 90px;
    height: 90px;
  }

  .skillBtnLogoPython,
  .skillBtnLogoScrum {
    width: 75px;
    height: 75px;
  }

  .skillBtnLogoDotNet,
  .skillBtnLogoAws {
    width: 100px;
    height: 100px;
  }
}